import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import TestOurTech_thankyou from "./Components/TestOurTech_thankyou";
import RegisterForm from "./Components/RegisterForm";
import NotFound from "./Components/NotFound";

function App() {
  if (process.env.REACT_APP_STAGE === "PROD")
    console.log = function no_console() {};
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/" component={RegisterForm} />
        <Route
          exact
          path="/TestOurTech_thankyou"
          component={TestOurTech_thankyou}
        />
        <Redirect to="/" /> */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
